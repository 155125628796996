<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <CustomerInformation />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import CustomerInformation from '@/components/dashboard/CustomerInformation.vue'

export default {
  name: 'ManagerGraphCustomerInformation',

  middleware: ['auth', 'manager'],
  components: {
    CustomerInformation
  },
  metaInfo () {
    return {
      title: 'Customer Information'
    }
  },

  mounted () {
    core.index()
  }

}
</script>

<style lang="scss" scoped>
.no-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1.5rem;
  height: 100%;

  h6 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
  }
  .icon-carret {
    color: #28a745;
  }
  div {
    margin: 0 auto;
    p {
      background: #ff7a29;
      color: white;
      text-align: center;
      border-radius: 100px;
      padding: 0 3rem;
    }
  }
}

.has-chart {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  h6 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 4rem;
  }
  .title {
    margin: 0 auto;
    p {
      color: white;
      text-align: center;
      padding: 0 3rem;
      background: #ff7a29;
      border-radius: 100px;
    }
  }
}

.border-right {
  border-right: 1px solid rgb(181, 178, 178);
}
</style>
