<template>

  <b-card>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="region">Filter By Region</label>
            <v-select
              id="region"
              v-model="selected.region"
              :options="list.regions"
              label="region_name"
              placeholder="-- Please Select Region --"
            >
              <template #option="{ region_name }">
                <span>{{ region_name }}</span>
              </template>
              <template #no-options="">
                No Available Region
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="province">Filter By province</label>
            <v-select
              id="province"
              v-model="selected.province"
              :options="list.provinces"
              label="province_name"
              placeholder="-- Please Select Province --"
            >
              <template #option="{ province_name }">
                <span>{{ province_name }}</span>
              </template>
              <template #no-options="">
                No Available Province
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.gender }}</h6>
            <div v-if="seriesGender.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsGender"
                :series="seriesGender"
              />
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.lead }}</h6>
            <div v-if="seriesLead.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsLead"
                :series="seriesLead"
              />
            </div>
          </div>
        </b-col>

        <!-- <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="no-chart">
            <h6>Average Age</h6>
            <h2>
              {{ average_age }}
            </h2>
          </div>
        </b-col> -->
      </b-row>

      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.hog_raiser }}</h6>
            <div v-if="seriesHogRaiser.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsHogRaiser"
                :series="seriesHogRaiser"
              />
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.poultry_raiser }}</h6>
            <div v-if="seriesPoultryRaiser.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsPoultryRaiser"
                :series="seriesPoultryRaiser"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.purchasing_behavior }}</h6>
            <div v-if="seriesPurchasingBehavior.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsPurchasingBehavior"
                :series="seriesPurchasingBehavior"
              />
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.purchase_day }}</h6>
            <div v-if="seriesPurchaseDay.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsPurchaseDay"
                :series="seriesPurchaseDay"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.purchase_time }}</h6>
            <div v-if="seriesPurchaseTime.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsPurchaseTime"
                :series="seriesPurchaseTime"
              />
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.digital_level }}</h6>
            <div v-if="seriesDigitalLevel.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsDigitalLevel"
                :series="seriesDigitalLevel"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.service_product }}</h6>
            <div v-if="seriesServiceProduct.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsServiceProduct"
                :series="seriesServiceProduct"
              />
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>{{ title.mode_of_communication }}</h6>
            <div v-if="seriesModeOfCommunication.length > 0">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsModeOfCommunication"
                :series="seriesModeOfCommunication"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>
<script>
import { core } from '@/config/pluginInit'
import { SharedStatisticsService, SharedListService } from '@/services'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'CustomerInformation',
  middleware: ['auth', 'manager', 'supervisor'],
  components: {
    VueApexCharts
  },
  data () {
    return {
      list: {
        regions: [],
        provinces: []
      },
      selected: {
        region: null,
        province: null
      },
      filter: {
        region: '',
        province: ''
      },
      title: {
        gender: 'Gender',
        lead: 'Lead',
        hog_raiser: 'Hog Raiser',
        poultry_raiser: 'Poultry Raiser',
        purchasing_behavior: 'Purchasing Behavior',
        purchase_day: 'Purchase Day',
        purchase_time: 'Purchase Time',
        service_product: 'Service Product',
        mode_of_communication: 'Mode Of Communication'
      },
      seriesGender: [],
      optionsGender: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesLead: [],
      optionsLead: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesHogRaiser: [],
      optionsHogRaiser: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesPoultryRaiser: [],
      optionsPoultryRaiser: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesPurchasingBehavior: [],
      optionsPurchasingBehavior: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesPurchaseDay: [],
      optionsPurchaseDay: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesPurchaseTime: [],
      optionsPurchaseTime: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesDigitalLevel: [],
      optionsDigitalLevel: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesServiceProduct: [],
      optionsServiceProduct: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      seriesModeOfCommunication: [],
      optionsModeOfCommunication: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },
      average_age: 0
    }
  },

  computed: {
    checkAverageAge () {
      return this.average_age !== 0
    }
  },

  watch: {
    'selected.region' (value) {
      this.filter.region = value?.id || ''
      this.getProvinces(this.filter.region)
      this.getStatistics()
    },
    'selected.province' (value) {
      this.filter.province = value?.id || ''
      this.getStatistics()
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
    this.getRegions()
  },

  methods: {
    async getRegions () {
      await SharedListService.getRegions().then(({ data }) => {
        this.list.regions = data
      })
    },

    async getProvinces (region) {
      this.selected.province = null
      if (region) {
        await SharedListService.getProvinces(`region_id=${region}`).then(
          ({ data }) => {
            this.list.provinces = data
          }
        )
      } else {
        this.list.provinces = []
      }
    },

    async getStatistics () {
      await SharedStatisticsService.getGeneralInformation(`region=${this.filter.region}&province=${this.filter.province}`)
        .then(({ data }) => {
          this.average_age = data?.average_age?.average_age || 0

          // gender graph
          this.seriesGender = data?.gender_graph?.total_count
          this.optionsGender = {
            ...this.optionsGender,
            labels: data?.gender_graph?.data
          }

          this.title.gender = 'Gender'

          // lead graph
          this.seriesLead = data?.lead_graph?.total_count
          this.optionsLead = {
            ...this.optionsLead,
            labels: data?.lead_graph?.data
          }

          this.title.lead = 'Lead'

          // hog raiser graph
          this.seriesHogRaiser = data?.hog_raiser_graph?.total_count
          this.optionsHogRaiser = {
            ...this.optionsHogRaiser,
            labels: data?.hog_raiser_graph?.data
          }

          this.title.hog_raiser = 'Hog Raiser'

          // poultry raiser graph
          this.seriesPoultryRaiser = data?.poultry_raiser_graph?.total_count
          this.optionsPoultryRaiser = {
            ...this.optionsPoultryRaiser,
            labels: data?.poultry_raiser_graph?.data
          }

          this.title.poultry_raiser = 'Poultry Raiser'

          // purchasing behavior graph
          this.seriesPurchasingBehavior = data?.purchasing_behavior_graph?.total_count
          this.optionsPurchasingBehavior = {
            ...this.optionsPurchasingBehavior,
            labels: data?.purchasing_behavior_graph?.data
          }

          this.title.purchasing_behavior = 'Purchasing Behavior'

          // purchasing day graph
          this.seriesPurchaseDay = data?.purchase_day_graph?.total_count
          this.optionsPurchaseDay = {
            ...this.optionsPurchaseDay,
            labels: data?.purchase_day_graph?.data
          }

          this.title.purchase_day = 'Purchase Day'

          // purchasing time graph
          this.seriesPurchaseTime = data?.purchase_time_graph?.total_count
          this.optionsPurchaseTime = {
            ...this.optionsPurchaseTime,
            labels: data?.purchase_time_graph?.data
          }

          this.title.purchase_time = 'Purchase Time'

          // digital level graph
          this.seriesDigitalLevel = data?.digital_level_graph?.total_count
          this.optionsDigitalLevel = {
            ...this.optionsDigitalLevel,
            labels: data?.digital_level_graph?.data
          }

          this.title.digital_level = 'Digital Level'

          // service product graph
          this.seriesServiceProduct = data?.service_product_graph?.total_count
          this.optionsServiceProduct = {
            ...this.optionsServiceProduct,
            labels: data?.service_product_graph?.data
          }

          this.title.service_product = 'Service Product'

          // mode of communication graph
          this.seriesModeOfCommunication = data?.mode_of_communication_graph?.total_count
          this.optionsModeOfCommunication = {
            ...this.optionsModeOfCommunication,
            labels: data?.mode_of_communication_graph?.data
          }

          this.title.mode_of_communication = 'Mode Of Communication'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.no-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1.5rem;
  height: 100%;

  h6 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
  }
  .icon-carret {
    color: #28a745;
  }
  div {
    margin: 0 auto;
    p {
      background: #ff7a29;
      color: white;
      text-align: center;
      border-radius: 100px;
      padding: 0 3rem;
    }
  }
}

.has-chart {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  h6 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 4rem;
  }
  .title {
    margin: 0 auto;
    p {
      color: white;
      text-align: center;
      padding: 0 3rem;
      background: #ff7a29;
      border-radius: 100px;
    }
  }
}

.border-right {
  border-right: 1px solid rgb(181, 178, 178);
}
</style>
